import React from 'react'

const Nav = () => {
  return (
    <nav>
        <div className="logo">
          <h1 className="typewriter txt-green">Naseer Khan</h1>
          <div className="blink"></div>
        </div>
      </nav>
  )
}

export default Nav