"use client"
import React, { useState, useEffect } from "react"
// import { CopyBlock, dracula } from 'react-code-blocks';
import MyCodeComponent from "../mycodecomponent/MyCodeComponent"

const Git = () => {
  const [fileContent, setFileContent] = useState("")

  // Fetch the content of code.txt after the component mounts
  useEffect(() => {
    fetch("/code/git.txt")
      .then((response) => response.text()) // Read as text
      .then((text) => setFileContent(text)) // Set the file content in state
      .catch((error) => console.error("Error fetching file:", error))
  }, [])
  return (
    <div className="container">
      <h1>Git</h1>
      <MyCodeComponent code={fileContent} lang={"bash"} />
    </div>
  )
}

export default Git
