import React from 'react'
import Image from "../image/Image"
import Whatsapp from "../../assets/contact/whatsapp.svg"
import Education from "../../assets/contact/education.svg"
import Email from "../../assets/contact/email.svg"
import Google from "../../assets/contact/google.svg"

const Cv = () => {
  return (
    <div className="cv">
    <div className="container">
      <h1 className="heading bg-title">About Me</h1>
      <p>
        As a Full Stack Developer, I handle both the front-end and back-end
        web projects to create seamless and engaging user experiences. My
        focus is on developing effective, user-friendly solutions that achieve
        great results. I blend technical expertise with a knack for
        problem-solving to deliver innovative applications.
      </p>

      <div>
        <div className="contact">
        <Image
            src={Education}
            height={25}
            width={25}
            alt="MCS"
          />
          <span className="txt-light-green">
            Masters of Computer Sciences<sup> 2018</sup>
          </span>
        </div>

        <div className="contact">
        <Image
            src={Whatsapp}
            height={23}
            width={23}
            alt="WA"
          />
          <span className="txt-light-green">+92 332 465 9119</span>
        </div>

        <div className="contact email">
        <Image
            src={Google}
            height={27}
            width={27}
            alt="Email"
          />
          <span className="txt-light-green">naseer4uplus@gmail.com</span>
        </div>

        <div className="contact email">
        <Image
            src={Email}
            height={30}
            width={30}
            alt="Email"
          />
          <span className="txt-light-green">contact@naseer.one</span>
        </div>
      </div>
    </div>

    <div className="container">
          <h1 className="heading bg-title">My Services</h1>
          <ul>
              <li className="txt-light-green">* Web Development (with)</li>
              <ul>
                  <li>- Wordpress</li>
                  <li>- React.js</li>
                  <li>- Node.js</li>
                  <li>- Express.js</li>
                  <li>- MongoDB</li>
              </ul>

              <li className="txt-light-green">* Mobile App Development (with)</li>
              <ul>
                  <li>- Bare React Native</li>
                  <li>- React Native Expo</li>

              </ul>
              <li className="txt-light-green">* UI / UX Design (with)</li>
              <ul>
                  <li>- Adobe XD</li>
                  <li>- Figma</li>

              </ul>
              <li className="txt-light-green">* Graphics Designing (with)</li>
              <ul>
                  <li>- Adobe Illustrator</li>
                  <li>- Adobe Photoshop</li>
                  <li>- Affinity Designer</li>

              </ul>
              <li className="txt-light-green">* IT Support</li>
          </ul>




      </div>

      <div className="container">
  <h1 className="heading bg-title">Worked with Technologies</h1>
  <div className="flex-container">
      <div className="flex-item">
          <ul>
              <li>Javascript</li>
              <li>Typescript</li>
              <li>Reactjs</li>
              <li>Nextjs</li>
              <li>React-Native</li>
              <li>Nodejs</li>
              <li>Express</li>
              <li>Objectionjs</li>
              <li>knexjs</li>
              <li>GraphQL</li>
              <li>Git</li>
              <li>Github</li>
              <li>Gitlab</li>
              <li>Docker</li>
              <li>Html5</li>
              <li>CSS3</li>
          </ul>
      </div>
      <div className="flex-item">
          <ul>
              <li>jQuery</li>
              <li>PHP</li>
              <li>CodeIgniter</li>
              <li>WordPress</li>
              <li>WP Theme Customization</li>
              <li>Shopify</li>
              <li>Strapi</li>
              <li>MS Excel</li>
              <li>MS Word</li>
              <li>VBA</li>
              <li>Peachtree</li>
              <li>Adobe Illustrator</li>
              <li>Adobe Photoshop</li>
              <li>Adobe XD</li>
              <li>Figma</li>
              <li>Affinity Designer</li>
          </ul>
      </div>
      <div className="flex-item">
          <ul>
              <li>MySQL</li>
              <li>SQL Server</li>
              <li>MongoDB</li>
              <li>DynamoDB</li>
              <li>PostgreSQL</li>
              <li>Firebase</li>
              <li>SQLite</li>
              <li>AWS Cognito</li>
              <li>OAuth</li>
              <li>AWS Lightsail</li>
              <li>AWS Lambda</li>
              <li>EC2</li>
              <li>Nginx</li>
              <li>Apache</li>
              <li>S3 Bucket</li>
              <li>Firestore</li>
          </ul>
      </div>
      <div className="flex-item">
          <ul>
              <li>MaterialUI</li>
              <li>Bootstrap</li>
              <li>Native Base</li>
              <li>MaterializeCSS</li>
              <li>TailwindCSS</li>
              <li>AntDesign</li>
              <li>Kafka</li>
              <li>Adminer</li>
              <li>RESTful APIs</li>
              <li>Kubernetes</li>
              <li>Ubuntu</li>
              <li>Linux Mint</li>
              <li>Windows</li>
              <li>VSCode</li>
              <li>Sublime Text</li>
          </ul>
      </div>
  </div>



</div>

    </div>
  )
}

export default Cv