import { CodeBlock,dracula} from 'react-code-blocks';
const MyCodeComponent = ({code,lang}) => (
  <CodeBlock
    text={code}
    theme={dracula}
    language={lang}
  />
)


export default MyCodeComponent