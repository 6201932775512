import React,{useState} from "react"
import "./styles.css"
import Nav from "./components/nav/Nav"
import Cli from "./components/cli/Cli"
import Cv from "./components/cv/Cv"
import Git from "./components/git/git"
import Docker from "./components/docker/docker"
import Javascript from "./components/js/Javascript"

function App() {
  const [showPage,setShowPage] = useState('cv')

  const handlePage = (page)=>{
    setShowPage(page);
  }

  const renderPage = ()=>{
    switch(showPage){
      case 'cv':
        return <Cv />
      case 'docker':
        return <Docker />
      case 'git':
        return <Git/>
      case 'javascript':
         return <Javascript/>  
      default:
        return <div></div>    
    }
  }
  return (
    <div className="bg-body">
      <Nav/>
      {<Cli handlePage={(page)=>handlePage(page)}/>}
      {renderPage()}
    </div>
  );
}

export default App;
